<template>
  <v-dialog
      v-model="isOpen"
      @input="$emit('cancel')"
      @click:outside="$emit('cancel')"
      :width="typeof big!=='undefined' ? 1024 : 425"
      scrollable
  >
    <v-card :loading="loading" class="dialogView">
      <v-toolbar class="bar" dense elevation="0" color="transparent" max-height="48px">
        <v-toolbar-title>{{ title }}</v-toolbar-title>
        <v-spacer/>
        <slot name="barActions"/>
        <v-btn icon @click="$emit('cancel')" class="ml-2">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <div v-if="!!this.$slots.header" class="header px-4 pt-2 pb-4 body-2">
        <slot name="header"/>
      </div>
      <v-divider/>
      <div v-if="!!this.$slots.content" :class="(typeof contentFullSize!=='undefined' ? '' : 'pa-4')+' overflow-y-auto'">
        <slot name="content"/>
      </div>
      <v-divider/>
      <v-card-actions v-if="!loading" class="footer">
        <v-btn text @click="$emit('cancel')">Отмена</v-btn>
        <v-spacer/>
        <slot name="actions"/>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: [
    'open', 'loading',
    'title',
    'cancelAction',
    'big', 'small',
    'contentFullSize'
  ],
  data: () => ({
    isOpen:false
  }),
  watch: {
    open(){
      this.isOpen = this.open;
    }
  }
}
</script>


<style scoped lang="scss">
.dialogView{
  > .bar,
  > .header,
  > .footer{
    background: #F7F7F7 !important;
  }
}
</style>