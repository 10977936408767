<template>
  <div>
    <v-card>
      <v-toolbar class="bar" dense elevation="0" color="transparent" max-height="48px">
        <v-toolbar-title>События</v-toolbar-title>
        <v-spacer/>
      </v-toolbar>
      <v-divider/>
      <div v-for="(event, index) in events" :key="event.id" class="px-4 pt-4">
        <v-row>
          <v-col v-if="!event.accept" cols="12" class="pb-0">
            <v-chip color="warning">Новое</v-chip>
          </v-col>
          <v-col cols="auto" style="width:300px;">
            <div class="caption mb-2">Материалы</div>
            <v-row>
              <v-col v-for="media in event.medias" :key="media.file" cols="6">
                <v-btn
                    v-if="media.file.endsWith('.mp4')"
                    @click="eventState.modalOpen(event, media)"
                    block depressed dense>
                  <v-icon class="mr-2" small>mdi-video</v-icon>Видео
                </v-btn>
                <v-btn
                    v-else
                    @click="eventState.modalOpen(event, media)"
                    block depressed dense>
                  <v-icon class="mr-2" small>mdi-image</v-icon>Фото
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
          <v-col>
            <div class="caption mb-2">Комментарий</div>
            <div>{{event.comment}}</div>
          </v-col>
          <v-col>
            <div class="caption mb-2">Дата</div>
            <div>{{event.created}}</div>
          </v-col>
          <v-col>
            <div class="caption mb-2">Местоположение</div>
            <v-btn
                :href="'https://yandex.ru/maps/43/kazan/?indoorLevel=1&ll='+event.lon+','+event.lat+'&mode=whatshere&whatshere%5Bpoint%5D='+event.lon+','+event.lat+'&whatshere%5Bzoom%5D=17&z=17'"
                target="_blank"
                depressed
                class="mb-2"
                dense
            >
              <v-icon class="mr-2" small>mdi-map</v-icon>Открыть на карте
            </v-btn>
          </v-col>
          <v-col cols="2">
            <div v-if="!event.accept" class="caption mb-2">Действия</div>
            <v-btn v-if="!event.accept" block depressed color="primary" @click="eventAccept(event.id)">
              <v-icon class="mr-2" small>mdi-check</v-icon>Рассмотрено
            </v-btn>
          </v-col>
        </v-row>
        <v-divider v-if="index<events.length-1" class="mt-4"/>
      </div>
    </v-card>
    <dialog-view :open="eventState.isModalOpen" title="Просмотр материала" cancelAction="true" big="" @cancel="eventState.modalClose()">
      <template v-slot:content v-if="eventState.modalData!=null">
        <video
            v-if="eventState.modalData1.file.endsWith('.mp4')"
            :src="eventState.modalData1.file" height="500" controls="controls" class="media">
        </video>
        <v-img
            v-else
            :src="eventState.modalData1.file" max-width="960px" max-height="600px" contain alt="" class="media">
          <template v-slot:placeholder>
            <v-row class="fill-height ma-0" align="center" justify="center">
              <v-progress-circular indeterminate color="primary"/>
            </v-row>
          </template>
        </v-img>
      </template>
    </dialog-view>
  </div>
</template>

<script>
import State from "@/plugins/state";
import Api from "@/Api";
import DialogView from "@/components/DialogView";

export default {
  components: {DialogView},
  data: () => ({
    eventsState: new State(),
    events:[],
    eventState: new State(),
    counterInterval:null
  }),
  mounted(){
    this.load();
  },
  created: function(){
    this.counterInterval = setInterval(()=>{
      this.load();
    }, 5000);
  },
  destroyed: function(){
    clearInterval(this.counterInterval)
  },
  methods:{
    load(){
      this.eventsState.stateLoading();
      Api.service.events.list((d)=>{
        this.eventsState.stateSuccess();
        this.events = d;
      }, ()=>{
        this.eventsState.stateError();
      })
    },
    eventAccept(id){
      Api.service.events.accept(id, ()=>{
        this.load();
      }, ()=>{

      })
    }
  }
};
</script>


<style>
.media{
  margin:auto;
  display: block;
}
</style>